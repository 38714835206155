/* In your main CSS file or component */
@import url('https://fonts.googleapis.com/css2?family=Lora&family=Montserrat:wght@700&family=Open+Sans:wght@600&family=Poppins:wght@500&family=Roboto:wght@400&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Hide scrollbar for Chrome, Safari, and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif !important;
}

p, span, a {
  font-family: 'Open Sans', sans-serif !important;
}

button {
  font-family: 'Poppins', sans-serif !important;
}

